import React from 'react';
import './css_file/categorynews.css'
import imagess from '../../json_files/random_data.json'
import Fade from 'react-reveal/Fade';

class Categorynews extends React.Component {
    render() {
        return (
            <div className="tab-news">
                <div className="container">
                    <div className="row" style={{ overflow: 'hidden' }}>
                        <Fade left delay={100}>
                            <div className="col-md-6">
                                <ul className="nav nav-pills nav-justified">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="pill" href="#viewed">Current Events</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="pill" href="#read">Upcoming Events</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="pill" href="#recent">Completetd Events</a>
                                    </li>
                                </ul>
                                <div className="tab-content" style={{ overflow: 'hidden' }}>
                                    <Fade left big cascade delay={50}>
                                        <div id="viewed" className="container tab-pane active">
                                            {
                                                imagess && imagess.map(
                                                    (val) => {
                                                        if (val.topic_id === 1) {
                                                            return (
                                                                val.details.map(
                                                                    (item) =>
                                                                        <div key={item.id} className="tn-news row col-sm-12">
                                                                            <div className="tn-img col-sm-4">
                                                                                <img src={item.pic_path} alt="img1" />
                                                                            </div>
                                                                            <div className="tn-title col-sm-8">
                                                                                <center><b>{item.ign}</b></center>
                                                                                <b>Real Name:</b><span className="style_data"> {item.real_name}</span><br />
                                                                                <b>Team Name:</b><span className="style_data"> {item.team_name}</span><br />
                                                                                <b>Yearly Earning:</b><span className="style_data"> {item.yearly_earning}</span><br />
                                                                            </div>
                                                                        </div>
                                                                )
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    }
                                                )
                                            }
                                        </div>
                                    </Fade>
                                    <Fade left big cascade delay={50}>
                                        <div id="read" className="container tab-pane fade">
                                            {
                                                imagess && imagess.map(
                                                    (val) => {
                                                        if (val.topic_id === 2) {
                                                            return (
                                                                val.details.map(
                                                                    (item) =>
                                                                        <div key={item.id} className="tn-news row col-sm-12">
                                                                            <div className="tn-img col-sm-4">
                                                                                <img src={item.pic_path} alt="img2" />
                                                                            </div>
                                                                            <div className="tn-title col-sm-8">
                                                                                <center><b>{item.game_name}</b></center>
                                                                                <b>Prizepool Distributed:</b><span className="style_data"> {item.prizepool_distributed}</span><br />
                                                                                <b>Total Players:</b><span className="style_data"> {item.total_players}</span><br />
                                                                                <b>Total Tournamnet held:</b><span className="style_data"> {item.total_tournament_held}</span><br />
                                                                            </div>
                                                                        </div>
                                                                )
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    }
                                                )
                                            }
                                        </div>
                                    </Fade>
                                    <Fade left big cascade delay={50}>
                                        <div id="recent" className="container tab-pane fade">
                                            {
                                                imagess && imagess.map(
                                                    (val) => {
                                                        if (val.topic_id === 3) {
                                                            return (
                                                                val.details.map(
                                                                    (item) =>
                                                                        <div key={item.id} className="tn-news row col-sm-12">
                                                                            <div className="tn-img col-sm-4">
                                                                                <img src={item.pic_path} alt="img3" />
                                                                            </div>
                                                                            <div className="tn-title col-sm-8">
                                                                                <center><b>{item.org_name}</b></center>
                                                                                <b>Prize Money Earned:</b><span className="style_data"> {item.prize_money_earned}</span><br />
                                                                                <b>Tournamnet:</b><span className="style_data"> {item.tournament}</span><br />
                                                                            </div>
                                                                        </div>
                                                                )
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    }
                                                )
                                            }
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </Fade>

                        <Fade right delay={100}>
                            <div className="col-md-6">
                                <ul className="nav nav-pills nav-justified">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="pill" href="#m-viewed">Veteran Scrims</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="pill" href="#m-read">Tier-2 Scrims</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="pill" href="#m-recent">Tier-3 Scrims</a>
                                    </li>
                                </ul>

                                <div className="tab-content" style={{ overflow: 'hidden' }}>
                                    <Fade right big cascade delay={100}>
                                        <div id="m-viewed" className="container tab-pane active">
                                            {
                                                imagess && imagess.map(
                                                    (val) => {
                                                        if (val.topic_id === 4) {
                                                            return (
                                                                val.details.map(
                                                                    (item) =>
                                                                        <div key={item.id} className="tn-news row col-sm-12">
                                                                            <div className="tn-img col-sm-4">
                                                                                <img src={item.pic_path} alt="img4" />
                                                                            </div>
                                                                            <div className="tn-title col-sm-8">
                                                                                <center><b>{item.game_name}</b></center>
                                                                                <b>Total Views:</b><span className="style_data"> {item.total_views}</span><br />
                                                                                <b>Views on:</b><span className="style_data"> {item.views_on}</span><br />
                                                                                <b>Watched Year:</b><span className="style_data"> {item.watched_year}</span><br />
                                                                            </div>
                                                                        </div>
                                                                )
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    }
                                                )
                                            }
                                        </div>
                                    </Fade>
                                    <Fade right big cascade delay={100}>
                                        <div id="m-read" className="container tab-pane fade">
                                            {
                                                imagess && imagess.map(
                                                    (val) => {
                                                        if (val.topic_id === 5) {
                                                            return (
                                                                val.details.map(
                                                                    (item) =>
                                                                        <div key={item.id} className="tn-news row col-sm-12">
                                                                            <div className="tn-img col-sm-4">
                                                                                <img src={item.pic_path} alt="img5" />
                                                                            </div>
                                                                            <div className="tn-title col-sm-8">
                                                                                <center><b>{item.game_name}</b></center>
                                                                                <b>Prizepool Distributed:</b><span className="style_data"> {item.Prizepool_Distributed}</span><br />
                                                                                <b>Total Players:</b><span className="style_data"> {item.Total_Players}</span><br />
                                                                                <b>Total Tournamnet held:</b><span className="style_data"> {item.Total_Tournament_held}</span><br />
                                                                            </div>
                                                                        </div>
                                                                )
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    }
                                                )
                                            }
                                        </div>
                                    </Fade>
                                    <Fade right big cascade delay={100}>
                                        <div id="m-recent" className="container tab-pane fade">
                                            {
                                                imagess && imagess.map(
                                                    (val) => {
                                                        if (val.topic_id === 6) {
                                                            return (
                                                                val.details.map(
                                                                    (item) =>
                                                                        <div key={item.id} className="tn-news row col-sm-12">
                                                                            <div className="tn-img col-sm-4">
                                                                                <img src={item.pic_path} alt="img6" />
                                                                            </div>
                                                                            <div className="tn-title col-sm-8">
                                                                                <center><b>{item.game_name}</b></center>
                                                                                <b>Prizepool Distributed:</b><span className="style_data"> {item.Prizepool_Distributed}</span><br />
                                                                                <b>Total Players:</b><span className="style_data"> {item.Total_Players}</span><br />
                                                                                <b>Total Tournamnet held:</b><span className="style_data"> {item.Total_Tournament_held}</span><br />
                                                                            </div>
                                                                        </div>
                                                                )
                                                            )
                                                        }
                                                        else
                                                            return null
                                                    }
                                                )
                                            }
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        );
    }
}

export default Categorynews;