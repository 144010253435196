import React from 'react';
import { Link } from "react-router-dom";
import './css_file/top_bar.css'
import Flip from 'react-reveal/Flip';

function EmailPhone(props) {
    return (
        <Flip bottom cascade >
            <div className="row">
                <div className="col-md-6">
                    <div className="tb-contact">
                        <p><i className="fas fa-envelope"></i>
                            <a href="mailto:osofficial@osesports.org">
                                {props.mail}
                            </a>
                        </p>
                        <p><i className="fas fa-phone-alt"></i>
                            <a href="tel:+91 ----- -----">
                                {props.phone}
                            </a>
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="tb-menu">
                        <Link to={props.about}>About</Link>
                        <Link to={props.privacy}>Privacy</Link>
                        <Link to={props.terms}>Terms</Link>
                        <Link to={props.contact}>Contact</Link>
                    </div>
                </div>
            </div>
        </Flip>
    );
}

class Topbar extends React.Component {
    render() {
        return (
            <div className="top-bar" style={{ overflow: 'hidden' }}>
                <div className="margin_LeftRight">
                    <EmailPhone mail="osofficial@osesports.org"
                        phone="+91 ----- -----" about="/about"
                        privacy="/privacy" terms="/terms"
                        contact="/contact"
                    />
                </div>
            </div>
        );
    }
}

export default Topbar;