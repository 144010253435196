import './scrims.css'
function AboutQues(props){
    return(
        <div className="col-sm-12">
            <div>
                    <h6 className="fontSize1"><b>Question {props.qid}: {props.ques}</b></h6>
                    <h6 className="fontSize1">&emsp;<b>Answer {props.qid}:</b> {props.ans}</h6><br></br>
            </div>
        </div>
    );
}
export default AboutQues