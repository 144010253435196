import React from 'react';
import social_details from '../../json_files/creative.json';
// import social_details from '../../json_files/social_analytics.json';

import './css_file/creative.css';
import logo from '../../img/logo.webp';
//animations
import Zoom from 'react-reveal/Zoom';

class Creatives extends React.Component {
    render() {
        return (
            <div className="social_analytic">
                <div className="" style={{ overflow: 'hidden' }}>
                    <Zoom top cascade delay={300}>
                        <div className="row">
                            <div className="col-lg-12">
                                <center>
                                    <h2 className="title_head bottom_line title_head_community">
                                        <Zoom top cascade>
                                            OS Creatives
                                        </Zoom>
                                    </h2>
                                </center>
                            </div>

                            {
                                social_details && social_details.map(
                                    (item) =>
                                        <div key={item.social_id} className="col-lg-3 ">
                                            <div className="columns_css test_css">
                                                <div className="tn-img">
                                                    <center>
                                                        <a href={item.link} target="_blank" rel="noreferrer noopener"
                                                            style={{ textDecoration: 'none', color: 'black' }}>
                                                            <img src={item.logo} className="img-css_creative" alt="img4" />
                                                        </a>
                                                    </center>
                                                </div>
                                                <center>
                                                    <h5 className="bottom_line">
                                                        <a href={item.link} target="_blank" className="social_title_size"
                                                            rel="noreferrer noopener"
                                                            style={{ textDecoration: 'none', color: 'black' }}>
                                                            {item.social_name}
                                                        </a>
                                                    </h5>
                                                    <h5 className="social_text_css">
                                                        {item.t1_data}
                                                    </h5>
                                                    <h5 className="social_head_css">
                                                        {item.t1}
                                                    </h5>
                                                    <hr />
                                                    <h5 className="social_text_css">
                                                        {item.t2_data}
                                                    </h5>
                                                    <h5 className="social_head_css">
                                                        {item.t2}
                                                    </h5>
                                                    <hr />
                                                    <h5 className="social_text_css">
                                                        {item.t3_data}
                                                    </h5>
                                                    <h5 className="social_head_css">
                                                        {item.t3}
                                                    </h5>
                                                </center>
                                            </div>
                                        </div>
                                )
                            }
                        </div>
                    </Zoom>
                </div>
            </div>
        );
    }
}

export default Creatives;