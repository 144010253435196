import React from 'react';
import {Link, useParams } from 'react-router-dom';
import tourney_details from '../../json_files/tournament_details.json';
import './css_file/tourney_details.css';  
import Fade from 'react-reveal/Fade';
import RubberBand from 'react-reveal/RubberBand';

function TourneyDetails() {
    let { id } = useParams();
    return(
                <div className="col-lg-9">
                    <Fade left big cascade delay={200}>
                        <div className="row">
                            {
                                tourney_details && tourney_details.map(
                                (item) => {
                                    if (item.tourney_id == id) {
                                        var colors = 'blue';
                                        if(item.tourney_status == "ongoing".toString()) colors = 'green'; 
                                        return (                                                    
                                            <RubberBand delay={200}>
                                                <div className="mn-img col-lg-12" style={{marginBottom:'1rem'}}>
                                                    <img src={'../'+item.poster}  alt={item.poster}/>
                                                </div>
                                                <h2 className="heading_CSS" style={{width:'100%', textAlign:'center'}}>
                                                    {item.title_name}
                                                </h2>
                                                <div style={{marginLeft:'3rem', width:'100%'}}>
                                                    <p>Tournament Status: <span style={{color:colors}}>
                                                            <b>{item.tourney_status}</b>
                                                        </span>
                                                    </p> 
                                                    <p>
                                                        <span>
                                                            Announce Date: <b>{item.announce_date}</b>
                                                        </span>
                                                    </p>
                                                    <p>Sponsored By: <span>
                                                            <b>{item.sponsored_by}</b>
                                                        </span>
                                                    </p>
                                                    <p>Managed By: <span>
                                                            <b>{item.managed_by}</b>
                                                        </span>
                                                    </p>
                                                    <p>Media Partner: <span>
                                                            <b>{item.media_partner}</b>
                                                        </span>
                                                    </p> 
                                                    <p>Registration Start from: <span>
                                                            <b>{item.reg_date}</b>
                                                        </span>
                                                    </p>
                                                    <p>Tournament Start from: <span>
                                                            <b>{item.start_date}</b>
                                                        </span>
                                                    </p>
                                                    <p>Entry: <span>
                                                            <b>{item.entry}</b>
                                                        </span>
                                                    </p>
                                                    <p>Description: <span>
                                                            <b>{item.description}</b>
                                                        </span>
                                                    </p>
                                                    <p>Registration Steps: <span>
                                                            <b>{item.reg_steps}</b>
                                                        </span>
                                                    </p>
                                                    <p>PrizePool: <span>
                                                            <b>{item.prizepool}</b>
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <span>
                                                            Slots: <b>{item.slots}</b>
                                                        </span>
                                                    </p>
                                                    <p>Youtube Live: <span>
                                                            <b>{item.youtube_live} Link --{'>'} <a href={item.yt_link}>Stream Link</a></b>
                                                        </span>
                                                    </p>
                                                    <p>Discord Link: <span> 
                                                        <b><a href={item.discord_link}> OS Discord Server</a></b>
                                                        </span>
                                                    </p>
                                                    <p>Tournament Rules: <span> 
                                                        <b>{item.rules}</b>
                                                        </span>
                                                    </p>
                                                    <table style={{width:'100%', textAlign:'center'}}>
                                                        <tr style={{width:'100%'}}>
                                                            <td><h5 style={{width:'100%'}}><u>Place</u></h5></td>
                                                            <td><h5 style={{width:'100%'}}><u>Prize</u></h5></td>
                                                            <td><h5 style={{width:'100%'}}><u>Winner</u></h5></td>
                                                        </tr>
                                                    {
                                                        item.prizepool_distribution.map(
                                                            (val) =><tr style={{width:'100%'}}>
                                                                        <td><h6 style={{width:'100%'}}>{val.title}</h6></td>
                                                                        <td><h6 style={{width:'100%'}}>{val.prize}</h6></td>
                                                                        <td><h6 style={{width:'100%'}}>{val.win}</h6></td>
                                                                    </tr>
                                                        )
                                                    }
                                                    </table>
                                                    <hr></hr>
                                                    <h4><u>Finals Point Table</u></h4>
                                                    <div className='row' style={{marginTop:'2rem'}}>
                                                    {
                                                        item.final_pointtable.map(
                                                            (val) =>
                                                            <div className="mn-img col-md-4">
                                                                <img src={'../'+val.pt_url}  alt={val.pt_url}/>   
                                                                <hr></hr>
                                                            </div>
                                                        )
                                                    }
                                                    </div>
                                                </div>
                                                
                                            </RubberBand>
                                            ) 
                                        
                                        }
                                        
                                    }
                                )
                            }
                        </div>
                    </Fade>
                </div>
    );

}

export default TourneyDetails;