import React from 'react';
import tourney_details from '../../json_files/tournament_details.json';
import './css_file/tourney_list.css';  
import RubberBand from 'react-reveal/RubberBand';
import TourneyCard from './tourneyCard';


function TourneyList() {
    return(
    <div className="main-news">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <RubberBand delay={200}><h2 className="heading_CSS"><u>Ongoing Tournaments</u></h2></RubberBand>
                    <hr></hr>
                    <div className="row">
                        {
                            tourney_details && tourney_details.map(
                            (item) =>
                                {
                                    if(item.tourney_status == "ongoing".toString())
                                    {
                                        return(
                                            <TourneyCard poster={item.poster} 
                                                    tourney_id={item.tourney_id} title_name={item.title_name} />
                                        )
                                    }
                                }                                        
                            )
                        }
                    </div>
                    <br></br>
                    <RubberBand delay={200}><h2 className="heading_CSS"><u>Completed Tournaments</u></h2></RubberBand>
                    <hr></hr>
                    <div className="row">
                        {
                            tourney_details && tourney_details.map(
                            (item) =>
                                {
                                    if(item.tourney_status == "completed".toString())
                                    {
                                        return(
                                            <TourneyCard poster={item.poster} 
                                                    tourney_id={item.tourney_id} title_name={item.title_name} />
                                        )
                                    }
                                }                                        
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default TourneyList;