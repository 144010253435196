import React from 'react';
import './css_file/our_css.css'
import ContentBox from './contentBox.js';
import CardsTitle from './cardsTitle.js';

class OurTeam extends React.Component {
    render() {
        return (
            <div className="our_services">
                <div className="container " style={{ overflow: 'hidden' }}>
                    <div className="row">
                        <CardsTitle title="Our Team" />
                        <ContentBox title="OSpRiMeX" icon=""
                            description="We started OS as a side hustle, but slowly and gradually it becomes
                            main stream and now it's member & managment team becomes a family to me. I consider 
                            myself lucky to be a part of it."
                        />
                        <ContentBox title="OSRishiOP" icon=""
                            description="OS has provided me hope in most crucial phase of my life. We created 
                            OS to give underdog teams a chance to shine in the Indian gaming community and I 
                            think, we are accomplishing the task with honesty and dedication. "
                        />
                        <ContentBox title="OSxBloodbone" icon=""
                            description="Working at OS Esports gives me feeling about working for underdogs 
                            & help them grow & shine, legitness is one of the major aim of OS ESPORTS"
                        />
                        <ContentBox title="Chayan Gaming" icon=""
                            description="OS esports is a very amazing esports organisation with many facilities 
                            and they do help underdog alot in growing up and compete against top tier teams and
                            help them to acheive what they want to in esports"
                        />
                        <ContentBox title="Tron Gaming" icon=""
                            description="The best supportive Org in supporting the upcoming underdog players 
                            along with the most helpful supportive Owner and the management. #dilse_OS❤️"
                        />
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default OurTeam;