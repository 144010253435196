import React from 'react';
import {Link } from 'react-router-dom';
import tourney_details from '../../json_files/list_of_tourney.json'
import './css_file/newsmenu.css'  
import Fade from 'react-reveal/Fade';
import RubberBand from 'react-reveal/RubberBand';

class MoreList extends React.Component{
    render(){
        return(
        <div className="main-news">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <RubberBand delay={200}><h2 className="heading_CSS">Tournaments List</h2></RubberBand>
                        <Fade left big cascade delay={200}>
                            <div className="row">
                                {
                                    tourney_details && tourney_details.map(
                                    (item) =>
                                            <div key={item.id} className="col-md-4">
                                                <div className="mn-img">
                                                    <img src={item.path} alt={item.id}/>
                                                    <div className="mn-title">
                                                        <Link to={'tourneydetails/' + item.id}>{item.tourney_title}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                }
                            </div>
                            <div>
                                <span><b><Link to='tourneylist'>more Tournaments</Link></b></span>
                            </div>
                        </Fade>
                    </div>

                    <div className="col-lg-3">
                        <div className="mn-list">
                            <RubberBand delay={200}><h2 className="heading_CSS">Read More</h2></RubberBand>
                            <Fade right big cascade delay={200}>
                                <ul>
                                    {
                                        tourney_details && tourney_details.map(
                                        (item) =>
                                                <li className="read_more_list" key={item.id}>
                                                    <Link to={'tourneydetails/' + item.id}>
                                                        <i className="far fa-hand-point-right"></i>  
                                                        &emsp;{item.tourney_title}
                                                    </Link>
                                                    <br/>
                                                </li>
                                        )
                                    }
                                </ul>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default MoreList;