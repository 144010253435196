import './scrims.css'
function ScrimsCard(props) {
    return (
        <div className="col-sm-6">
            <div className="boxScrimsCard cardFontSize">
                    <center><h6><b className="fontSize"><u>{props.name}</u></b></h6></center>
                    <h6 className="fontSize1"><b>Registration Time:</b> {props.regTime}</h6>
                    <h6 className="fontSize1"><b>{props.regAt}</b></h6>
                    <h6 className="fontSize1"><b>Match Time:</b> {props.startTime}</h6>
                    <h6 className="fontSize1"><b>Id-Pass Time:</b> {props.idPassTime}</h6>
                    <h6 className="fontSize1"><b>Id-Pass channel-{'>'}</b> {props.idPassTime}</h6>
            </div>
        </div>
    );
}
export default ScrimsCard;
