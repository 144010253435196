import scrimsdata from '../../json_files/scrimsDetails.json'
import ScrimsCard from './scrimCard.js';
import AboutQues from './aboutques.js';
import Emailing from "../topbar/emailing.js";
import Address from "../topbar/address.js";

function T3scrims() {
        return (
            <div className="T3scrims">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sectionMargin">
                            <h3>
                                <center><b className="Vhead">T3 Scrims Details</b></center>
                            </h3>
                            <br />
                            <div className="row">
                                {
                                    scrimsdata && scrimsdata.map(
                                        (val) => {
                                            if (val.tier === 3) {
                                                return (
                                                    val.timings.map(
                                                        (item) =>
                                                            <ScrimsCard name={item.scrimsName}
                                                                startTime={item.startTime}
                                                                regTime={item.regTime}
                                                                regAt={item.regAt}
                                                                idPassTime={item.idPassTime}
                                                            />
                                                    )
                                                )
                                            }
                                            else
                                                return null
                                        }
                                    )
                                }
                            </div>
                            <br/>
                            <hr/>
                        </div>
                        
                        <div className="col-lg-12 sectionMargin">
                            <h3>
                                <center><b className="Vhead">QnA T3 Scrims</b></center>
                            </h3>
                            <br /><br />
                            <div className="row">
                                {
                                    scrimsdata && scrimsdata.map(
                                        (val) => {
                                            if (val.tier === 3) {
                                                return (
                                                    val.about.map(
                                                        (item) =>
                                                            <AboutQues qid={item.qid}
                                                                ques={item.ques}
                                                                ans={item.ans}
                                                            />
                                                    )
                                                )
                                            }
                                            else
                                                return null
                                        }
                                    )
                                }
                            </div>
                            <hr/>
                        </div>

                        <div className="col-md-6 contact">
                            <div className="col">
                                <hr/>
                                <center><h4 ><b>Ask your query...</b></h4></center>
                                <hr/>
                                <Emailing />
                            </div>
                            <div className="col">
                                <Address />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
}

export default T3scrims;