import { useState } from "react";
import Axios from "axios";
function Emailing() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [query, setQuery] = useState("");
    const Query = async e => {
        e.preventDefault();
        Axios.post("http://localhost:8080/emailquery", {
            name: name,
            email: email,
            subject: subject,
            query: query,
        }).then((res) => {
            if (res.data.message != null)
                alert(res.data.message);
            else
                alert('Error in sending query');

        });
    };

    return (
        <div class="contact-form" >
            <form onSubmit={Query}>
                <div class="form-row">
                    <div class="form-group col-md-6" >
                        <input type="text" class="form-control" placeholder="Your Name"
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <input type="email" class="form-control" placeholder="Your Email"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div class="form-group " >
                    <input type="text" class="form-control" placeholder="Subject" style={{ width: '100%' }}
                        onChange={(event) => {
                            setSubject(event.target.value);
                        }}
                    />
                </div>
                <div class="form-group">
                    <textarea class="form-control " id="textarea" rows="5" placeholder="Type your Query" style={{ width: '95%' }}
                        onChange={(event) => {
                            setQuery(event.target.value);
                        }}
                    ></textarea>
                </div>
                <div>
                    <button class="btn form-control" style={{ width: '100%' }} type="submit">Send Message</button>
                </div>
            </form>
        </div>
    );
}
export default Emailing;