import SimpleSlider from './slider.js';
import MoreList from './moreList.js';
import Categorynews from './categorynews.js';
import SocialInsights from './socialInsights.js';
import Partner from './partner.js';
import Insta from './insta_feed.js';
import Services from './our_services.js';
import OurTeam from './ourTeam.js';
import Jarsey from './jarsey.js'
import EsportsContri from './esportsContri.js';
function Home() {

  return (
    <>
      <SimpleSlider />
      <SocialInsights />
      <Services />
      <EsportsContri />
      {/* <Categorynews /> */}
      <MoreList />
      <OurTeam />
      <Partner />
      {/* <Insta /> */}
      <br></br>
      <br></br>
      <Jarsey />
      <br /><br />
    </>
  );
}

export default Home;
