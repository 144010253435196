import React from 'react';
import logo from '../../img/logo.webp';
import { Link } from 'react-router-dom';
import './css_file/brand.css'
import Bounce from 'react-reveal/Bounce';

class Brand extends React.Component{
    render(){
        return(
            <div className="brand">
                <div className="brand_LeftRight">
                    <div className="row align-items-center" style={{overflow: 'hidden'}}>
                    <Bounce right delay={100}>
                        <div className="col-lg-12 col-md-4">
                            <div className="b-logo">
                                <Link to="/">
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                        </div>
                    </Bounce>
                        {/* <div class="col-lg-3 col-md-4">
                            <div class="b-ads">
                                <h1 style={{fontFamily:'Arial Rounded MT Bold', margin:'0px', padding:'0px',}} className="title_brand">
                                    <b><Bounce right cascade>ESPORTS</Bounce></b>
                                </h1>
                            </div>
                        </div> 
                        <div class="col-lg-6 col-md-4">
                            <div class="b-search">
                                <h4 style={{fontFamily:'Arial Rounded MT Bold',textAlign:'right'}} className="title_brand">
                                    <b><Bounce right cascade>Leading Organization in promoting Underdog talents.</Bounce></b>
                                </h4>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        );
    }
}
export default Brand;