
import {Switch,  Route,  Redirect, useParams} from "react-router-dom";

//component

import Topbar from './components/Navbar/Topbar.js';
import Brand from './components/Navbar/brand.js';
import Navbar from './components/Navbar/navbar.js';
import Footer from './components/Footer/footer.js';
import Home from './components/Home/home.js';
import About from './components/topbar/About';
import Privacy from './components/topbar/Privacy';
import Contact from './components/topbar/Contact';
import Terms from './components/topbar/Terms';
import Veteran from "./components/scrims/veteran.js";
import T2scrims from "./components/scrims/tier2.js";
import T3scrims from "./components/scrims/tier3.js";
import TourneyList from "./components/tournaments/tourney_list.js";
import DetailsPage from "./components/tournaments/detailpage.js";
import VisitorCount from "./components/visitorCounter.js";
import Creatives from "./components/otherpages/creatives.js";

//protected page

// import Admin from './components/admin-module/admin';
// import Organizer from './components/organizer-module/organizer';
// import Player from './components/player-module/player';

//css

import './css/App.css';
import './css/style.css';
import './css/bootstrap.min.css';
// import './css/signup.css';
// import './lib/slick/slick.css';
// import './lib/slick/slick-theme.css';
// import './lib/slick/slick-theme.less';
// import './lib/slick/slick-theme.scss';
// import './lib/slick/slick.less';

//script

import './lib/slick/slick.js';
import './js/main.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import './lib/easing/easing.js';

function App() {
  return (
    <>
      <Topbar />
      <Brand />
      <Navbar />
      <Switch>
            {/* home */}
              <Route exact path= "/">
                  <Home/>
              </Route>

            {/* about privacy terms conatct */}
              <Route exact path= "/about">
                  <About />
              </Route>
              <Route exact path= "/privacy">
                  <Privacy />
              </Route>
              <Route exact path= "/terms">
                  <Terms />
              </Route>
              <Route exact path= "/contact">
                  <Contact />
              </Route>

            {/* tournament list */}
              <Route exact path= "/tourneylist">
                  <TourneyList />
              </Route>
              <Route exact path= "/tourneydetails/:id">
                  <DetailsPage />
              </Route>

            {/* scrims */}
              <Route exact path= "/vScrims">
                  <Veteran />
              </Route>
              <Route exact path= "/t2Scrims">
                  <T2scrims />
              </Route>
              <Route exact path= "/t3Scrims">
                  <T3scrims />
              </Route>

            {/* other pages */}
              <Route exact path= "/megawar">
                  <Contact />
              </Route>
              {/* <Route exact path= "/oslineup">
                  <Contact />
              </Route> */}
              <Route exact path= "/oscreatives">
                  <Creatives />
              </Route>
            
              {/* <Route exact path= "/admin">
                  <Admin />
              </Route>
              <Route exact path= "/organizer">
                  <Organizer />
              </Route>
              <Route exact path= "/player">
                  <Player />
              </Route> */}
              
              <Redirect to="/" />     
      </Switch>
      <VisitorCount/>
      <Footer />
      
    </>
  );
}

export default App;
