import contactJSON from '../../json_files/contact.json'
function Address() {
    return (
        <div class="contact-info">
            <h3>Get in Touch</h3>
            {
                contactJSON && contactJSON.map((val) =>
                    <div>
                        <p><i class="fa fa-map-marker"></i> {val.address}</p>
                        <p><i class="fa fa-envelope"></i> {val.mail}</p>
                        <p><i class="fa fa-phone"></i> {val.phone}</p>
                        <div class="social" style={{ overflow: 'hidden' }}>
                            <a href={val.insta} target="_blank" rel="noreferrer">
                                <i title={val.i_title} class={val.i_icon}></i>
                            </a>
                            <a href={val.youtube} target="_blank" rel="noreferrer">
                                <i title={val.y_title} class={val.y_icon}></i>
                            </a>
                            <a href={val.discord} target="_blank" rel="noreferrer">
                                <i title={val.d_title} class={val.d_icon}></i>
                            </a>
                            {/* <a href={val.rooter} target="_blank" rel="noreferrer">
                                <i title={val.r_title} class={val.r_icon}></i>
                            </a> */}
                            <a href={val.facebook} target="_blank">
                                <i title={val.f_title} class={val.f_icon}></i>
                            </a>
                            <a href={val.twitter} target="_blank">
                                <i title={val.t_title} class={val.t_icon}></i>
                            </a>
                        </div>
                    </div>
                )
            }

        </div>
    );
}
export default Address;