import React from 'react';
import Slider from "react-slick";
// import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import images from '../../json_files/list_of_tourney.json'

import './css_file/topnews.css'

function SlideInfo(props) {
    return (
        <div key={props.key} className="tn-img">
            <img src={props.path} alt={props.path} />
        </div>
    );
}

export default function SimpleSlider() {
    var settings = {
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        draggable: true,
        fade: true,
    };
    return (
        <Zoom bottom>
            <Slider {...settings}>
                {
                    images && images.map(
                        (item) =>
                            <SlideInfo key={item.id} path={item.path} />
                    )
                }
            </Slider>
        </Zoom>

    );
}

