
import "./css_file/about_privacy_terms.css"
const Privacy=() => {
    return (
        <div className="container">
        <div className="row">
            <div className="col-lg-12">
      
            <div class="content_back">
      <div class="detail_box_smooth">
      <h4 class="detail_box_title">OS Esports Privacy Policy</h4>
      <div class="info_description">
      <p>
      <div class="content_main">
      The OS Esports Privacy Policy governs the manner in which the osesports.org website ("site") 
      collects, uses, maintains and discloses information collected from OS Esports Discord Server, 
      OS Esports Intsagram, OS Esports Youtube and Community. This privacy policy applies to the site and
      all products and services offered here.
         
          </div>
          </p>
          </div>
          <hr></hr>
          <h2 class="detail_box_subtitle">Personal Identification User Information
          </h2>
          <div class="info_description"><p>
          <div class="content_main">
Users may be asked for, as appropriate, user name or email address. Users, however, may visit the site 
anonymously. We will collect user name or email address information from users only if they voluntarily 
submit such information to us. Users can always refuse to supply personally identification information.
 </div>
</p>
</div>
<hr></hr>
<h2 class="detail_box_subtitle">Non-Personal Identification User Information</h2>
<div class="info_description"><p>
<div class="content_main">
We may collect non-personal identification information about users whenever they interact with the site. 
Non-personal identification information may include the users' means of interaction to the site, such as 
browser information, screen resolution, operating system, the Internet service providers utilized and 
other similar information.
</div>
</p>
</div>
<hr></hr>
<h2 class="detail_box_subtitle">Web Browser Cookies</h2>
<div class="info_description"><p>
<div class="content_main">
The site may use "cookies" to enhance functionality of site features. A user's web browser places 
cookies on their hard drive for record-keeping purposes and sometimes to track information about 
them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are 
being sent. Note that some parts of the site may not function properly should the user elect to 
disable cookies.
</div>
</p>
</div>

<hr></hr>
<h2 class="detail_box_subtitle">How We Use Collected User Information</h2>
<div class="info_description">
<p>
<div class="content_main">
The site only collect email addresses and names, and uses for the following purposes:
<ul>
<li>to improve user experience</li>
<li>to improve products and services provided by the site</li>
<li>to run a tournaments, contest, survey or other site feature</li>

<p>

</p>
</ul>
</div>
</p>
</div>
<hr></hr>
<h2 class="detail_box_subtitle">How We Protect User Information</h2>
<div class="info_description"><p>
<div class="content_main">
We adopt appropriate data collection, storage and processing practices and security measures to 
protect against unauthorized access, alteration, disclosure or destruction of your email addresses 
and it is stored on our site. 
</div>
</p>
</div>
<hr></hr>
<h2 class="detail_box_subtitle">Sharing Your Personal Information</h2><div class="info_description"><p>
<div class="content_main">
We do not collect any personal information (except Email addresses) of any users of this website to others. 
Email addresses will never be shared with third-parties for marketing, spam, promotional activities, 
or for any reason or no reason.
</div>
</p>
</div>
<hr></hr>
<h2 class="detail_box_subtitle">Changes to this Privacy Policy</h2><div class="info_description"><p>
<div class="content_main">
We reserve the right to update this privacy policy at any time without notice. We encourage users 
to frequently check this page for any changes to stay informed. You acknowledge and agree that it 
is your responsibility to review this privacy policy periodically and become aware of any modifications.
</div>
</p>
</div>
<hr></hr>
<h2 class="detail_box_subtitle">Your Acceptance of These Terms</h2><div class="info_description"><p>
<div class="content_main">
By using this site, you signify your acceptance of this policy. If you do not agree to this policy,
 please do not use this site. Your continued use of the site following any changes to this policy 
 will be deemed your acceptance of those changes.
 </div>
</p>
</div>
<hr></hr>
<h2 class="detail_box_subtitle">Contacting Us</h2>
<div class="info_description"><p>
<div class="content_main">
If you have any questions about this Privacy Policy, the practices of this site, or your dealings with
 this site, please contact us at:
Email: osofficial@osesports.org
</div>

</p>
</div>
<br></br>

</div>
</div>
</div>

</div>
</div>
    );
};

export default Privacy;