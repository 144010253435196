import Zoom from 'react-reveal/Zoom';
import {Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade'

function TourneyCard(props) {
    return (
        <Fade left big cascade delay={200}>
            <div className="col-md-4">
                <div className="mn-img">
                    <img src={props.poster} />
                    <div className="mn-title">
                        <Link to={'tourneydetails/' + props.tourney_id}>
                            {props.title_name}
                        </Link>
                    </div>
                </div>
            </div>
        </Fade>
    );
}
export default TourneyCard;