import "./css_file/Contact.css"
import Emailing from "./emailing.js";
import Address from "./address.js";

function Contact() {
    return (
            <div class="contact">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-8">
                            <Emailing />
                        </div>
                        <div class="col-md-4">
                            <Address />
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Contact;