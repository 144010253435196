import React from 'react';
import './css_file/our_css.css'
import ContentBox from './contentBox.js';
import CardsTitle from './cardsTitle.js';

class EsportsContri extends React.Component {
    render() {
        return (
            <div className="our_services">
                <div className="container ">
                    <div className="row">
                        <CardsTitle title=" Our Esports Facilities" />
                        <ContentBox title="OS Creatives" icon="fas fa-headset icon_size"
                            description="OS Creatives is OS Esports initiatives to create a friendly eco-system for 
                            Content Creators of tomorrow, where creators can create there awesome content without worrying about 
                            the reach and promotion. OS helps them growth there brand with OS Community. "
                        />
                        <ContentBox title="Tournaments" icon="fab fa-d-and-d icon_size"
                            description="OS Esports provides a vast range of tournaments and scrims for the practice of the teams and 
                            excel in skills. OS supports underdog teams actively by promoting best talents to compete with 
                            global teams."
                        />
                        <ContentBox title="Invitationals" icon="fab fa-phoenix-framework icon_size"
                            description="OS Invitationals are the tournaments where OS Esports invites national & international teams 
                            to complete with each other. They are purely invite only tournaments."
                        />
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default EsportsContri;