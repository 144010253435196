import TourneyDetails from "./tourney_details";
import MoreTourney from "./seeMoreTourney";
import { useParams } from "react-router-dom";
function DetailsPage() {
  return (
    <div className="main-news">
        <div className="container">
            <div className="row">
                <TourneyDetails />
                <MoreTourney />
            </div>
        </div>
    </div>
  );
}

export default DetailsPage;