import React from 'react';
import './css_file/our_css.css'
import ContentBox from './contentBox.js';
import CardsTitle from './cardsTitle.js';

class Services extends React.Component {
    render() {
        return (
            <div className="our_services">
                <div className="container " style={{ overflow: 'hidden' }}>
                    <div className="row">
                        <CardsTitle title="Our Services" />
                        <ContentBox title="Events Management" icon="fas fa-atom icon_size"
                            description="OS Esports is an leading Indian esports organisation.
                                We provide BGMI Tournaments, Scrims, Customs to underdog teams in India.
                                We aim to become India's face in global esports community."
                        />
                        <ContentBox title="Partner Program" icon="far fa-handshake icon_size"
                            description="Become a partner with OS Esports and get exciting &
                                exclusive benefits of one of the largest esports community."
                        />
                        <ContentBox title="Promotion" icon="fas fa-crown icon_size"
                            description="OS Esports has large social presence. We have one of the
                                loyal and supportive social family. We can provide you
                                all the reach you need to promote your business and excel
                                in your endeavour."
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;