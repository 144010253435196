import React from 'react';
import social_details from '../../json_files/social_analytics.json'
import './css_file/social_insights.css'
import logo from '../../img/logo.webp'
//animations
import Zoom from 'react-reveal/Zoom';

class SocialInsights extends React.Component {
    render() {
        return (
            <div className="social_analytic">
                <div className="container " style={{ overflow: 'hidden' }}>
                    <Zoom top cascade delay={300}>
                        <div className="row">
                            <div className="col-lg-12">
                                <center>
                                    <div className="title_head bottom_line">
                                        <Zoom top cascade>
                                            OS Esports Vision
                                        </Zoom>
                                    </div>
                                </center>
                            </div>
                            <div className="col-lg-12">
                                <Zoom top cascade>
                                    <div className="columns_css padding_vision">
                                        <div className="tn-img">
                                            <center>
                                                <img src={logo} className="logo-css" alt="img4" />
                                            </center>
                                        </div>
                                        <div className="test_css">
                                            <center>
                                                <p>
                                                    <b>OS Esports</b> is one of the <b>leading Indian eSports organisation</b> founded in September 2020. <br />
                                                    OS is prominently working on <b>promoting UNDERDOG</b> talents in Indian eSports community to <b>GLOBAL stages</b>,
                                                    with the aim to help all the emerging teams and clans to improve in their eSports career and
                                                    <b> compete with National and International teams</b> and make India proud on Global stage.<br />
                                                    <b>Authenticity</b> and <b>Transparency</b> are one of the import guiding principles for OS ESPORTS.<br /> </p>
                                            </center>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>

                            <div className="col-lg-12">
                                <center>
                                    <h2 className="title_head bottom_line title_head_community">
                                        <Zoom top cascade>
                                            Our Community
                                        </Zoom>
                                    </h2>
                                </center>
                            </div>

                            {
                                social_details && social_details.map(
                                    (item) =>
                                        <div key={item.social_id} className="col-lg-4 ">
                                            <div className="columns_css test_css">
                                                <div className="tn-img">
                                                    <center>
                                                        <a href={item.link} target="_blank" rel="noreferrer noopener"
                                                            style={{ textDecoration: 'none', color: 'black' }}>
                                                            <img src={item.logo} className="img-css" alt="img4" />
                                                        </a>
                                                    </center>
                                                </div>
                                                <center>
                                                    <h5 className="bottom_line">
                                                        <a href={item.link} target="_blank" className="social_title_size"
                                                            rel="noreferrer noopener"
                                                            style={{ textDecoration: 'none', color: 'black' }}>
                                                            {item.social_name}
                                                        </a>
                                                    </h5>
                                                    <h5 className="social_text_css">
                                                        {item.t1_data}
                                                    </h5>
                                                    <h5 className="social_head_css">
                                                        {item.t1}
                                                    </h5>
                                                    <hr />
                                                    <h5 className="social_text_css">
                                                        {item.t2_data}
                                                    </h5>
                                                    <h5 className="social_head_css">
                                                        {item.t2}
                                                    </h5>
                                                    <hr />
                                                    <h5 className="social_text_css">
                                                        {item.t3_data}
                                                    </h5>
                                                    <h5 className="social_head_css">
                                                        {item.t3}
                                                    </h5>
                                                </center>
                                            </div>
                                        </div>
                                )
                            }
                        </div>
                    </Zoom>
                </div>
            </div>
        );
    }
}

export default SocialInsights;