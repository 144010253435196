import Zoom from 'react-reveal/Zoom';

function ContentBox(props) {
    return (
        <Zoom top cascade delay={100}>
            <div className="col-lg-4 ">
                <center>
                    <i className={props.icon}></i>
                    <h2>
                        <Zoom top cascade delay={0}>
                            {props.title}
                        </Zoom>
                    </h2>
                    <p>
                        {props.description}
                    </p>
                </center>
            </div>
        </Zoom>
    );
}
export default ContentBox;