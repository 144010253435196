import Zoom from 'react-reveal/Zoom';

function VisitorCount() {
    return (
        <Zoom top cascade delay={100}>
            <div style={{textAlign:'center',marginBottom:'1rem'}}>
                <h3><u>Visitor Count</u></h3>
                <img border="0" title="hit counters" alt="hit counters" 
                    src="https://counter2.stat.ovh/private/freecounterstat.php?c=8sjcydhtsthwlpmfzkrsg8snzfyskpte"/>
            </div>
        </Zoom>
    );
}
export default VisitorCount;