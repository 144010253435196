import Zoom from 'react-reveal/Zoom';

function CardsTitle(props) {
    return (
        <Zoom top cascade delay={100}>
            <div className="col-lg-12">
                <center>
                    <h2 className="service_title">
                        <Zoom top cascade delay={0}>
                            {props.title}
                        </Zoom>
                    </h2>
                </center>
            </div>
        </Zoom>
    );
}
export default CardsTitle;