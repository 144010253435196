import React from 'react';
import { Link } from 'react-router-dom';
import './css_file/navBar.css'
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import contactJSON from '../../json_files/contact.json'
// function signupbtn() {
//     document.getElementById('Sign-up-wrapper').style.display='block';
//   }

// function loginbtn() {
//     document.getElementById('Log-in-wrapper').style.display='block';
//   }  


class Navbar extends React.Component {
    render() {
        return (
            <div className="nav-bar">
                <div className="nav_LeftRight_Margin">
                    <nav className="navbar navbar-expand-md navbar-dark">
                        <Link to="/" className="navbar-brand" style={{fontFamily: 'cursive', color:'gray'}}>osesports</Link>
                        <button type="button" className="navbar-toggler menu_btn" data-toggle="collapse" data-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav mr-auto">
                                <Link to="/" className="nav-item nav-link" >
                                    <Zoom top cascade delay={200}>
                                        <span><i class="fas fa-home"></i></span>
                                        <span className="navbarfontColor"> home</span>
                                    </Zoom>
                                </Link>
                                <Link to="/tourneylist" className="nav-item nav-link">
                                    <Zoom top cascade delay={200}>
                                        <span className="navbarfontColor" >Tournaments</span>
                                    </Zoom>
                                </Link>
                                <div className="nav-item dropdown">
                                    <a href="" className="nav-link dropdown-toggle" data-toggle="dropdown"><span className="navbarfontColor">Scrims Details</span></a>
                                    <div className="dropdown-menu">
                                        <Link to="/vScrims" className="dropdown-item">Veteran Scrims</Link>
                                        <Link to="/t2Scrims" className="dropdown-item">Tier-2 Scrims</Link>
                                        <Link to="/t3Scrims" className="dropdown-item">Tier-3 Scrims</Link>
                                    </div>
                                </div>
                                <Link to="/megawars" className="nav-item nav-link">
                                    <Zoom top cascade delay={200}>
                                        <span className="navbarfontColor" title='coming soon'>Mega War</span>
                                    </Zoom>
                                </Link>
                                {/* <Link to="/oslineups" className="nav-item nav-link">
                                    <Zoom top cascade delay={200}>
                                        <span className="navbarfontColor" title='coming soon'>OS Lineup</span>
                                    </Zoom>
                                </Link> */}
                                <Link to="/oscreatives" className="nav-item nav-link">
                                    <Zoom top cascade delay={200}>
                                        <span className="navbarfontColor" title='coming soon'>OS Creatives</span>
                                    </Zoom>
                                </Link>
                            </div>
                            {
                                contactJSON && contactJSON.map((val) =>
                                    <div key={val.d_title} className="social ml-auto" style={{ overflow: 'hidden' }}>
                                        {/* 
                                            <Link onClick={signupbtn}><i class="fas fa-users"></i></Link>
                                            <Link onClick={loginbtn}><i class="fas fa-sign-in-alt"></i></Link>
                                        */}
                                        <Bounce right cascade delay={200}>
                                            <a href={val.insta} target="_blank" rel="noreferrer">
                                                <i title={val.i_title} className={val.i_icon}></i>
                                            </a>
                                            <a href={val.youtube} target="_blank" rel="noreferrer">
                                                <i title={val.y_title} className={val.y_icon}></i>
                                            </a>
                                            <a href={val.discord} target="_blank" rel="noreferrer">
                                                <i title={val.d_title} className={val.d_icon}></i>
                                            </a>
                                            {/* <a href={val.rooter} target="_blank" rel="noreferrer">
                                                <i title={val.r_title} className={val.r_icon}></i>
                                            </a> */}
                                            <a href={val.facebook} target="_blank">
                                                <i title={val.f_title} className={val.f_icon}></i>
                                            </a>
                                            <a href={val.twitter} target="_blank">
                                                <i title={val.t_title} className={val.t_icon}></i>
                                            </a>
                                        </Bounce>
                                    </div>
                                )
                            }

                        </div>
                    </nav>
                </div>

            </div>
        );
    }
}

export default Navbar;