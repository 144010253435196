
import React from 'react';
import './css_file/our_css.css'
import CardsTitle from './cardsTitle.js';
import images from '../../json_files/partner.json'

class Partner extends React.Component {
    render() {
        return (
            <div className="our_services">
                <div className="container " style={{ overflow: 'hidden' }}>
                    <div className="row">
                        <CardsTitle title="Partnership" />
                            {
                                images && images.map(
                                    (item) =>
                                    <>
                                        <div key={item.id} className="col-sm-3 partner-img">
                                            <img src={item.path} alt={item.path} />
                                            <center>
                                                {item.title}
                                            </center>
                                        </div>
                                        
                                    </>
                                )
                            }
                            
                        
                    </div>
                </div>
                <br></br>
                <br></br>
            </div>
        );
    }
}

export default Partner;