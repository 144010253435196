import Emailing from "../topbar/emailing.js";
import Address from "../topbar/address.js";
import './scrims.css';
import Zoom from 'react-reveal/Zoom'

function T2scrims() {
    return (
        <div>
            <div className="contact">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 textShadow">
                            <center>
                                <h2 className="Vhead"><b>Tier-2 Scrims</b></h2>
                                <p className="fontSize">
                                    TIER-2 is a invite only scrims. And the Invitation will be send on the bases of performance in various OS Esports Tournaments.
                                    There are some other, below listed ways through which you can apply for OS Tier-2 Scrims.
                                </p>
                            </center>
                            <br/>
                            <h5>
                                <b><u>REQUIREMENTS  FOR TIER-2 SCRIMS</u></b>
                            </h5>
                            <br/>
                            <h6>
                                <b>1-{'>'} <u>OFFICIAL ACHIEVEMENTS</u></b>
                            </h6>
                            <p className="fontSize1">
                                &emsp;&emsp;1.1- PMIS FINALIST <br/>
                                &emsp;&emsp;1.2- PMCO FINALIST <br/>
                                &emsp;&emsp;1.3- PMIT  FINALIST <br/>
                            </p>
                            <h6>
                                <b>2-{'>'} <u>UNOFFICIAL ACHIEVEMENTS</u></b>
                            </h6>
                            <p className="fontSize1">
                                
                                &emsp;&emsp;2.1- 10K + TOURNAMENT WINNER <br/>
                                &emsp;&emsp;2.2- 30K + TOURNAMENT TOP 10  <br/>
                                &emsp;&emsp;2.3- 50K + TOURNAMENT FINALIST  <br/>
                                &emsp;&emsp;2.4- PMOC GRAND FINALIST  <br/>
                                &emsp;&emsp;2.5- FREINDSHIP CUP GRAND FINALIST  <br/>
                            </p>

                            <span className="notes">NOTE - MINIMUM 3 PLAYERS SHOULD QUALIFY THE ABOVE REQUIREMENT</span> 
                            <br/><br/>
                            
                            <h7 className="fontSize">For Successful checking you need these proof: </h7><br/>
                            <p className="fontSize1">
                                &emsp;&emsp;1. CREW SS <br/>
                                &emsp;&emsp;2. PROFILE SS <br/>
                                &emsp;&emsp;3. PRIZEPOOL INFO SS <br/>
                                &emsp;&emsp;4. LIVE STREAM LINK <br/>
                                &emsp;&emsp;5. TIME STAMP OF LIVE STREAM <br/>
                                &emsp;&emsp;6. MAIL CONFIRMATION (ONLY FOR OFFICIAL ACHIEVEMENT) <br/>
                                &emsp;&emsp;7. SLOT LIST SS OR POINTS TABLE SS <br/>
                            </p>

                            <span className="notes">PLAYING T2 OF OTHER SERVERS WILL NOT BE ACCEPTED,WAIT FOR 2-3 DAYS 
                            AFTER SENDING ACHIEVEMENTS WHATSAPP SCREENSHOTS WILL NOT BE ACCEPTED</span><br/>
                            <br/>
                            <h7>
                                For more details contact us on our 
                                <a href="https://discord.gg/76UwmKejuh" target="_blank" rel="noreferrer"> Discord. </a>
                            </h7>
                        </div>

                        <div className="col-md-4">
                            <div className="col">
                                <hr/>
                                <center><h4 ><b>Ask your query...</b></h4></center>
                                <hr/>
                                <Emailing />
                            </div>
                            <div className="col">
                                <Address />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default T2scrims;