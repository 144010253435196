
import React from 'react';
import './css_file/our_css.css'
import CardsTitle from './cardsTitle.js';
import images from '../../json_files/jarsey.json'

class Jarsey extends React.Component {
    render() {
        return (
            <div className="our_services">
                <div className="container " style={{ overflow: 'hidden' }}>
                    <div className="row">
                        <CardsTitle title="New Jarsey Announcement" />
                            {
                                images && images.map(
                                    (item) =>
                                        <div key={item.id} className="col-sm-4 partner-img">
                                            <img src={item.path} alt={item.path} />
                                        </div>
                                )
                            }
                            
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default Jarsey;