import "./css_file/about_privacy_terms.css"

const About = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div class="content_back">
              <h4 class="detail_box_title"> Welcome!! Know About Gaming Industry</h4>
              <div class="content_main">
                As we all know ESports Business is growing at an exponential rate, In past 5 years ESports 
                Community has grown 3000% with a market revenue of 5 billion US$ in 2019. Mobile games, 
                PC games like DOTA2, CS-GO, LOL, PUBG, FREEFIRE, CODM, VALORANT, FORT-NIGHT etc are 
                among the most popular games.
                <br></br>
                <ul>
                  <li> DOTA-2 Prizepool Distribution – 250 million US$</li>
                  <li>CS-GO Prizepool Distribution – 110 million US$</li>
                  <li> LOL Prizepool Distribution – 82 millions US$ , etc</li>
                </ul>
                On an average 400-500 Official Tournament are held by various games in a year. And approx. 
                2000+ Unofficial Tournament in a years.<br/><br/>
                OS ESPORTS Are Here With A Perfect Opportunity For Underdogs Who Have Been Waiting Eagerly 
                For Our Brand New Tournament, This Is Going To Be The Place Where You Get To Showcase Your 
                Skills And Rise Up In Esports.<br/><br/>
                OS Esports is one of the leading Indian eSports organisation founded in September 2020.
                OS is prominently working on promoting UNDERDOG talents in Indian eSports community to 
                GLOBAL stages, with the aim to help all the emerging teams and clans to improve in their 
                eSports career and compete with National and International teams and make India proud on 
                Global stage. Authenticity and Transparency are one of the import guiding principles for 
                OS ESPORTS.
              </div>
              <hr></hr>
              <div>
                <h2 class="detail_box_subtitle"> How It Works</h2>
                <div class="info_description">
                  <p>
                    <div class="content_main">
                      This website provides prize money rankings, as well as historical data on events 
                      that are organized or managed by OS Esports in past 1.5 years. We collect all the 
                      data from our tournaments and upload the same on our website.
                    </div>
                  </p>
                </div>
              </div>
              <hr></hr>
              <div>
                <h2 class="detail_box_subtitle"> Disclaimer</h2>
                <div class="info_description">
                  <p>
                    <div class="content_main">
                      There may be some data inconsistency in the recorded data. Tournaments date, 
                      Prizepool Distribution may vary for some tournaments.
                    </div><br></br>
                  </p>
                </div>
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;